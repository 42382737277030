<template>
    <b-overlay :show="showLoader">
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="data-border">
                    <div class="border-text bgCard">
                        {{$t('invoices.bill')}}
                    </div>

                    <!-- CONTENT -->
                    <div style="padding-bottom: 2px" class="d-flex align-items-between">
                        <validation-observer ref="validationInvoiceData">
                            <div class="row p-2">
                                <div class="col-12">
                                    <b-form-group :label="$t('invoices.bill_number')" :disabled="true">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.bill_number')" rules="required">
                                            <b-form-input v-if="invoice" v-model="invoice.bill_id"/>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider >
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-form-group :disabled="true" :label="$t('invoices.reference')">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.bill_number')" rules="required">
                                            <b-form-input v-if="invoice" v-model="invoice.reference"/>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-form-group :label="$t('invoices.trr')">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.trr')" rules="required">
                                            <b-form-input v-if="invoice" v-model="invoice.trr"/>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-form-group :label="$t('invoices.date_start')">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.date_start')" rules="required">
                                            <date-picker :locale="currentLocale" :first-day-of-week="2" v-model="invoice.date_start" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" :is-required="true">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="formatDate(invoice.date_start)"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-form-group :label="$t('invoices.date_end')">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.date_end')" rules="required">
                                            <date-picker :locale="currentLocale" :first-day-of-week="2" v-model="invoice.date_end" :min-date="invoice.date_start" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" :is-required="true">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="formatDate(invoice.date_end)"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-4">
                                    <b-form-group :label="$t('form_data.post_code')">
                                        <validation-provider #default="{ errors }" :name="$t('form_data.post_code')" rules="required|min:4">
                                            <b-form-input v-if="invoice" v-model="invoice.postal_code"/>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-8">
                                    <b-form-group :label="$t('form_data.city')">
                                        <validation-provider #default="{ errors }" :name="$t('form_data.city')" rules="required|min:2">
                                            <b-form-input v-if="invoice" v-model="invoice.city"/>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-form-group :label="$t('invoices.timestamp')">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.timestamp')" rules="required">
                                            <date-picker :locale="currentLocale" :first-day-of-week="2" v-model="invoice.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig"  :is-required="true">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="formatDate(invoice.timestamp)"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-6">
                                    <b-form-group :label="$t('invoices.payment_deadline')">
                                        <validation-provider #default="{ errors }" :name="$t('invoices.payment_deadline')" rules="required">
                                            <date-picker :locale="currentLocale" :first-day-of-week="2" v-model="invoice.payment_deadline" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" :is-required="true">
                                                <template v-slot="{ inputValue, inputEvents }">
                                                    <input class="form-control"
                                                           :value="formatDate(invoice.payment_deadline)"
                                                           v-on="inputEvents"
                                                    />
                                                </template>
                                            </date-picker>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-md-12">
                                    <b-form-group :label="$t('material.note')">
                                        <validation-provider #default="{ errors }" :name="$t('material.note')" rules="max:130">
                                            <b-form-textarea
                                                v-model="invoice.note"
                                                :placeholder="$t('material.enter_note_placeholder')"
                                                rows="2"
                                                max-rows="2"
                                            ></b-form-textarea>
                                            <small class="text-danger">{{errors[0]}}</small>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                            </div>
                        </validation-observer>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="data-border">
                    <div class="border-text bgCard">
                        {{$t('material.client_data')}}
                    </div>

                    <!-- CONTENT -->
                    <div style="padding-bottom: 2px">
                        <div class="row p-2">
                            <div class="col-12">
                                <b-form-group :label="$t('material.client')" :disabled="true">
                                    <b-form-input v-if="buyer" v-model="buyer.name"/>
                                    <b-form-input v-else />
                                </b-form-group>
                            </div>
                            <div class="col-12">
                                <b-form-group :disabled="true" :label="$t('form_data.address')">
                                    <b-form-input v-if="buyer" v-model="buyer.address"/>
                                    <b-form-input v-else />
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-12">
                                <b-form-group :disabled="true" :label="$t('form_data.contact_person')">
                                    <b-form-input v-if="buyer && buyer.contact" v-model="buyer.contact.name" />
                                    <b-form-input v-else />
                                </b-form-group>
                            </div>
                            <div class="col-12 col-md-12">
                                <b-form-group :disabled="true" :label="$t('form_data.phone')">
                                    <b-form-input v-if="buyer && buyer.contact" v-model="buyer.contact.phone" />
                                    <b-form-input v-else />
                                </b-form-group>
                            </div>
                            <div class="col-12 col-12">
                                <b-form-group :disabled="true" :label="$t('form_data.country')">
                                    <b-form-input v-if="buyer" v-model="buyer.country"/>
                                    <b-form-input v-else />
                                </b-form-group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ARTIKLI -->
        <div class="my-2 d-flex justify-content-between align-items-center">
            <div>
                <h4 class="card-title my-auto">{{ $t('invoices.articles') }}</h4>
            </div>
            <div class="d-flex flex-row">
                <div v-if="manualInput" class="pr-2">
                    <b-button variant="primary" class="pr-2" @click="openNameChoosingModal">{{$t('invoices.join_by_vat')}}</b-button>
                </div>
                <div v-if="manualInput">
                    <b-button variant="primary" @click="addManualInput">{{ $t('invoices.add_article') }}</b-button>
                </div>
                <div class="d-flex align-items-end pl-2">
                    <b-form-checkbox v-model="manualInput" @change="manualInputChanged">
                        <h4>
                            <b-badge v-if="manualInput" variant="success" size="md">{{$t('invoices.manual_input')}}</b-badge>
                            <b-badge v-else variant="danger" size="md">{{$t('invoices.manual_input')}}</b-badge>
                        </h4>
                    </b-form-checkbox>
                </div>
            </div>
        </div>

        <div v-if="showItems" class="">
            <Table name="items" ref="itemsTable" :columnDefs="columnDefs" :rowData="showItems" @editRow="editRow" @deleteRow="removeItemFromTable"/>
        </div>

        <div class="mt-2">
            <h4 class="card-title my-auto">{{$t('invoices.settlement_vat')}}</h4>
        </div>

        <div v-if="invoice.taxes" class="mt-2 mb-1">
            <Table name="taxes" ref="taxes" :columnDefs="columnDefsTaxes" :rowData="invoice.taxes"/>
        </div>
        <!-- TAXES -->
        <div class="row">
            <div class="col-12 col-md-8 pr-md-0 mt-2">
                <div class="data-border">
                    <div class="border-text bgCard">
                        {{$t('invoices.invoice_total')}}
                    </div>

                    <!-- CONTENT -->
                    <div class="row p-2">
                        <div class="col-6 text-price">
                            {{ $t('material.total_price') }}:
                        </div>
                        <div class="col-6 mb-1 text-price-bold text-right">
                            {{$numberRounding(invoice.total_price)}}€
                        </div>
                        <div class="col-6 text-price">
                            {{ $t('material.total_price_without_vat') }}:
                        </div>
                        <div class="col-6 text-price-bold text-right">
                            {{$numberRounding(invoice.total_price_without_vat)}}€
                        </div>
                        <div v-if="manualInput && remainingPrice > 0" class="p-1 m-0">
                            <b-alert class="p-1 m-0" show variant="warning">
                                {{$t('invoices.missing_amount')}} {{ parseFloat(remainingPrice / 1000000).toFixed(2) }}€.
                            </b-alert>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <b-button variant="primary" @click="validate" :disabled="manualInput && $numberRounding(invoice.total_price) !== $numberRounding(currentTotalOfItems)">
                {{$t('invoices.issue_invoice')}}
            </b-button>
        </div>
        <choose-joining-name ref="joinByVat" v-on:chosenName="chosenName"></choose-joining-name>
        <add-manual-input-to-invoice ref="addManualInput" v-on:addManualItem="addManualItem" :remaining-price="remainingPrice" :invoice="invoice" :taxes-map="taxesMap"></add-manual-input-to-invoice>
        <confirmation-modal ref="confirmManualInputSwitch" @cancel="cancelConfirmationModal" @confirm="confirmConfirmationModal" @close="closeConfirmationModal" :title="$t('invoices.confirmation_modal_title')" :text="$t('invoices.confirmation_modal_text')"></confirmation-modal>
    </b-overlay>
</template>

<script>
    import { BOverlay, BFormInput, BFormGroup, BFormCheckbox, BBadge, BButton, BAlert, BFormTextarea} from 'bootstrap-vue'
    import {DatePicker} from 'v-calendar'
    import Table from '@/views/components/Table/Table.vue'
    import AddManualInputToInvoice from '@/views/Invoices/Unissued/components/AddManualInputToInvoice.vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import ConfirmationModal from '@/views/components/ConfirmationModal.vue'
    import ChooseJoiningName from '@/views/Invoices/Unissued/components/ChooseJoiningName.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components:{
            ChooseJoiningName,
            ConfirmationModal,
            AddManualInputToInvoice,
            BAlert,
            BOverlay,
            BFormInput,
            BFormGroup,
            DatePicker,
            Table,
            BFormCheckbox,
            BBadge,
            BButton,
            BFormTextarea,
            ValidationProvider,
            ValidationObserver },
        props: {
            invoice: Object
        },
        data() {
            return {
                step: 0,
                showLoader: false,
                buyer: {},
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                columnDefs: [
                    { headerName: this.$t('table_fields.name'),  editable: false, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.quantity'),  editable: false, field: 'quantity', filter: true, cellRenderer: (params) => this.getQuantity(params.data)},
                    { headerName: this.$t('table_fields.price_per_unit'),  editable: false, field: 'price_per_item', filter: true, cellRenderer: 'DisplayPrice'},
                    { headerName: this.$t('table_fields.tax_rate'),  editable: false, field: 'vat', filter: true, cellRenderer:'DisplayPercent'},
                    { headerName: this.$t('table_fields.discount_percentage'),  editable: false, field: 'discount', filter: true, cellRenderer:'DisplayPercent'},
                    { headerName: this.$t('table_fields.total_price'),  editable: false, field: 'total_price', filter: true, cellRenderer:'DisplayPrice'}
                ],
                columnDefsTaxes: [
                    { headerName: this.$t('table_fields.tax_rate'),  editable: false, field: 'vat', filter: false, floatingFilter: false, cellRenderer:'DisplayPercent'},
                    { headerName: this.$t('table_fields.base'),  editable: false, field: 'total_price_without_vat', filter: false, floatingFilter: false, cellRenderer:'DisplayPrice'},
                    { headerName: this.$t('table_fields.vat'),  editable: false, field: 'only_vat', filter: false, cellRenderer: (params) => this.getOnlyVat(params.data), floatingFilter: false},
                    { headerName: this.$t('table_fields.with_ddv'),  editable: false, field: 'total_price', filter: false, floatingFilter: false, cellRenderer:'DisplayPrice'}
                ],
                manualInput: false,
                showItems: [],
                currentTotalOfItems: 0,
                remainingPrice: 0,
                showColumn: [],
                taxesMap: new Map(),
                required
            }
        },
        computed: {
            currentLocale() {
                return this.$store.getters['user/current_locale']
            }
        },
        methods: {
            formatDate(inputDate) {
                return this.dayjs(inputDate).format('dddd, DD.MM.YYYY')
            },
            validate() {
                this.$refs.validationInvoiceData.validate().then((success) =>  {
                    if (success) {
                        this.finishInvoice()
                    } else {
                        this.$printWarning(this.$t('print.warning.fill_all_fields'))
                    }
                })
            },
            chosenName(payload) {
                this.currentTotalOfItems = 0
                this.remainingPrice = 0
                this.showItems = []
                for (const i in this.invoice.taxes) {
                    const tax = this.invoice.taxes[i]
                    this.calculateTaxes(tax)
                    const obj = {
                        id: i,
                        name: `${payload.name} ${tax.vat / 100}%`,
                        quantity: 100,
                        price_per_item: tax.total_price,
                        price_per_item_without_vat: tax.price_per_item_without_vat,
                        total_price: tax.total_price,
                        total_price_without_vat: tax.total_price_without_vat,
                        discount: 0,
                        vat: tax.vat
                    }
                    this.showItems.push(obj)
                    this.currentTotalOfItems += tax.total_price
                }
                this.remainingPrice = this.invoice.total_price - this.currentTotalOfItems
                this.$refs.itemsTable.refresh()
            },
            async finishInvoice() {
                const updateObj = { ...this.invoice }
                if (this.manualInput) {
                    updateObj.items = this.showItems
                }
                updateObj.postal_code = parseInt(updateObj.postal_code)
                try {
                    await this.$http.put(`/api/client/v1/invoices/${this.invoice.id}`, updateObj)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.$router.push({name:'invoices'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            openNameChoosingModal() {
                this.$refs.joinByVat.open()
            },
            calculateTaxes(item) {
                if (!this.taxesMap.has(item.vat)) {
                    let totalPrice = 0
                    for (const i in this.invoice.taxes) {
                        if (this.invoice.taxes[i].vat === item.vat) {
                            totalPrice = this.invoice.taxes[i].total_price
                        }
                    }
                    this.taxesMap.set(item.vat, totalPrice - item.total_price)
                } else {
                    const ele = this.taxesMap.get(item.vat)
                    this.taxesMap.set(item.vat, ele - item.total_price)
                }
                this.$refs.taxes.refresh()
            },
            closeConfirmationModal() {
                this.manualInput = !this.manualInput
            },
            cancelConfirmationModal() {
                this.manualInput = !this.manualInput
            },
            confirmConfirmationModal() {
                this.showItems = this.invoice.items
                this.remainingPrice = 0
                this.showColumn = this.columnDefs
                this.columnDefs = this.columnDefs.filter(ele => ele.colId !== 'actions')
                this.columnDefsTaxes = this.columnDefsTaxes.filter(ele => ele.colId !== 'difference')
                this.taxesMap = new Map()
                this.$refs.itemsTable.refresh()
            },
            getQuantity(data) {
                return `${(Math.round(((data.quantity / 100) + Number.EPSILON) * 100) / 100).toString()}`
            },
            getOnlyVat(data) {
                return `${this.$numberRounding(data.total_price - data.total_price_without_vat, 2)} €`
            },
            manualInputChanged() {
                if (this.manualInput) {
                    this.showItems = []
                    this.currentTotalOfItems = 0
                    this.remainingPrice = this.invoice.total_price - this.currentTotalOfItems
                    this.columnDefs.push({headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', minWidth: 150})
                    this.$refs.itemsTable.refresh()
                    this.initializeTaxesMap()
                    this.columnDefsTaxes.push({ headerName: this.$t('table_fields.difference'),  editable: false, colId: 'difference', field: 'vat', filter: false, cellRenderer: 'DisplayDifference', cellRendererParams: (params) => { return {value: this.differenceByTax(params.data)} }, floatingFilter: false})
                } else {
                    this.$refs.confirmManualInputSwitch.show()
                }
            },
            initializeTaxesMap() {
                for (const i in this.invoice.taxes) {
                    if (!this.taxesMap.has(this.invoice.taxes[i].vat)) {
                        this.taxesMap.set(this.invoice.taxes[i].vat, this.invoice.taxes[i].total_price)
                    }
                }
            },
            differenceByTax(data) {
                return this.$numberRounding(this.taxesMap.get(data.vat))
            },
            addManualInput() {
                this.$refs.addManualInput.open()
            },
            addManualItem(payload, edited) {
                if (edited) {
                    payload.id = parseInt(payload.id, 10)
                    const array = JSON.parse(JSON.stringify(this.showItems))
                    array[payload.id] = JSON.parse(JSON.stringify(payload))
                    this.showItems = array
                    this.$refs.itemsTable.refresh()
                } else {
                    payload.id = this.showItems.length
                    this.showItems.push(payload)
                }

                this.currentTotalOfItems += payload.total_price

                this.calculateTaxes(payload)

                this.remainingPrice = this.invoice.total_price - this.currentTotalOfItems
                this.$refs.itemsTable.refresh()
                this.$refs.taxes.refresh()
            },
            editRow(data, index) {
                this.$refs.addManualInput.open(data, index)
            },
            async removeItemFromTable(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    if (id > -1) {
                        const index = this.showItems.findIndex(ele => ele.id === id)
                        if (index < 0) return
                        this.currentTotalOfItems -= this.showItems[index].total_price
                        const ele = this.taxesMap.get(this.showItems[index].vat)
                        this.taxesMap.set(this.showItems[index].vat, ele + this.showItems[index].total_price)
                        this.showItems.splice(index, 1)
                    }

                    this.remainingPrice = this.invoice.total_price - this.currentTotalOfItems
                    this.$refs.itemsTable.refresh()
                    this.$refs.taxes.refresh()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            }
        },
        mounted() {
            this.buyer = this.invoice.buyer
            this.showItems = this.invoice.items
            this.showColumn = this.columnDefs

            this.invoice.timestamp = this.$dayjs(this.invoice.timestamp).toISOString()
            this.invoice.payment_deadline = this.$dayjs(this.invoice.payment_deadline).toISOString()
        }
    }
</script>

<style lang="scss" scoped>
.data-border{
  border: 2px solid #646464;
  margin: 5px;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
  background: white;
  padding: 0 15px;
  font-weight: bold;
}

.text-price-bold{
  font-weight: bold;
  font-size: 17px;
}

.text-price{
  font-size: 17px;
}
</style>