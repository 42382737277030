<template>
    <div>
        <div class="data-border">
            <div class="border-text bgCard">
                {{ $t('invoices.client_data') }}
            </div>
                    
            <!-- CONTENT -->
            <div style="padding-bottom: 2px">
                <div class="row p-2">
                    <div class="col-12">
                        <b-form-group :label="$t('material.client')">
                            <validation-provider #default="{ errors }" :name="$t('material.client')" rules="required">
                                <v-select v-model="buyer" appendToBody label="name" :options="buyers" @input="changeBuyer"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-12">
                        <b-form-group :disabled="true" :label="$t('form_data.address')">
                            <b-form-input v-if="buyer" v-model="buyer.address"/>
                            <b-form-input v-else />
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group :disabled="true" :label="$t('form_data.contact_person')">
                            <b-form-input v-if="buyer && buyer.contact" v-model="buyer.contact.name" />
                            <b-form-input v-else />
                        </b-form-group>
                    </div>
                    <div class="col-12 col-md-6">
                        <b-form-group :disabled="true" :label="$t('form_data.phone')">
                            <b-form-input v-if="buyer && buyer.contact" v-model="buyer.contact.phone" />
                            <b-form-input v-else />
                        </b-form-group>
                    </div>
                    <div class="col-12 col-12">
                        <b-form-group :disabled="true" :label="$t('form_data.phone')">
                            <b-form-input v-if="buyer" v-model="buyer.country"/>
                            <b-form-input v-else />
                        </b-form-group>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="buyer" class="pt-3">
            <table-with-check-box :columnDefs="columnDefs" :rowData="billsFromPos" :selected="items" selectValue="id" :findValueInArray="true" @selectionChanged="selectionChanged"/>
        </div>
        <div class="d-flex justify-content-end mt-2">
            <b-button variant="primary" :disabled="items.length <= 0" @click="createInvoice">
                {{$t('general.next_step')}}
            </b-button>
        </div>
    </div>
</template>

<script>
    import {  BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import { ValidationProvider } from 'vee-validate'
    import { required } from '@validations'
    import TableWithCheckBox from '@/views/components/Table/TableWithCheckBox.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components:{ vSelect, ValidationProvider, TableWithCheckBox, BFormGroup, BFormInput, BButton },
        data() {
            return {
                required,
                buyer: null,
                buyers: [],
                billsFromPos: [],
                items: [],
                userData: null,
                createdInvoice: []
            }
        },
        computed: {
            columnDefs() {
                return [
                    { headerName: this.$t('table_fields.uuid'), field: 'invoice_printed_id', filter: false, editable: false, cellRenderer: (params) => this.getInvoiceId(params.data)},
                    { headerName: this.$t('table_fields.total'), field: 'total_price', filter: true, editable: false, cellRenderer: 'DisplayPrice'},
                    { headerName: this.$t('table_fields.date'),
                      field: 'timestamp',
                      filter: true,
                      floatingFilterComponentParams: {type: 'date'},
                      filterParams: {
                          textCustomComparator: this.$datesInRange
                      },
                      editable: false,
                      cellRenderer: 'DisplayTimeStamp'}
                ]
            }
        },
        methods:{
            selectionChanged(val) {
                this.items = val
            },
            getInvoiceId(item) {
                if (!item) {
                    return '/'
                }

                if (item.custom_numbering_id && item.custom_numbering_id !== '') return `${item.custom_numbering_id}-${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
                else return `${item.bill_busines_unit_id}-${item.bill_cash_register_id}-${item.bill_identifier}`
            },
            async loadData() {
                try {
                    this.$emit('turnOnLoader')

                    const response = await this.$http.get('/api/client/v1/invoices/buyers')
                    this.buyers = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.$emit('turnOffLoader')
                }
            },
            async createInvoice() {
                try {
                    const payload = {
                        status: 0,
                        issued_by: this.userData,
                        buyer: this.buyer,
                        invoice_ids: this.items
                    }
                
                    const response = await this.$http.post('/api/client/v1/invoices/', payload)
                    this.createdInvoice = response.data ?? []
                    this.$emit('finishFirstStep', this.createdInvoice)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async changeBuyer() {
                try {
                    this.$emit('turnOnLoader')

                    const response = await this.$http.get(`/api/client/v1/invoices/buyers/${this.buyer.id}`)
                    this.billsFromPos = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.$emit('turnOffLoader')
                }
            }
        },
        mounted() {
            this.userData = this.$store.getters['user/userData']
        
            if (!this.userData) {
                this.userData = {
                    name: ''
                }
            }
            this.loadData()
        }
    }
</script>

<style lang="scss" scoped>
    .data-border{
        border: 2px solid #646464;
        margin: 5px;
        padding: 10px;
        position: relative;
    }
    
    .border-text{
        position: absolute;
        top: -12px;
        background: white;
        padding: 0 15px;
        font-weight: bold;
    }
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
</style>